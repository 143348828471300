import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.jpeg';
import Ticker from './ticker';
import twitter from '../assets/x.png';
import mail from '../assets/mail.png';
import teleg from '../assets/tg.png';
import bomb from '../assets/bomb.png';
import os from '../assets/os.png';
import rn from '../assets/road-runner.gif';

const tweet = () => {
	window.open("https://x.com/adhdbrosnfts");
}

const telegL = () => {
	window.open("https://t.me/ADHDBROSNFTS");
}

const email = () => {
	window.open("mailto:info@adhdbrosnfts@yahoo.com");
}

const osL = () => {
	window.open("https://testnets.opensea.io/collection/adhdbrosnfts");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">

            <div class="footer">

                <img class="logoF" src={logo} />
                <div class="iconsF">
                <img onClick={tweet} src={twitter} />
                <img onClick={telegL} src={teleg} />
                <img onClick={email} src={mail} />
                <img onClick={osL} src={os} />

                </div>

                <div class="copyright">Copyright © 2024 ADHD⚡BROS⚡NFTs. All Rights Reserved</div>
            </div>

            <img className='bomb' src={bomb}/>
             <div className='rnDiv'><img className='rn' src={rn}/></div>

        </div>
        )
    }
}

export default Footer;

