import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import $ from "jquery";
import cool from '../assets/cool.png'
import check from '../assets/check.gif'
import up from '../assets/arrow-up.png';

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 2000);
});
var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let galleryData = [];
let attr = [];
let trait = [];
let value = [];
let loginWindow = 0;
let imgURL;
let edition;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "setPause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price1",
				"type": "uint256"
			}
		],
		"name": "setPrice1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price2",
				"type": "uint256"
			}
		],
		"name": "setPrice2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price3",
				"type": "uint256"
			}
		],
		"name": "setPrice3",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_price4",
				"type": "uint256"
			}
		],
		"name": "setPrice4",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenID",
				"type": "uint256"
			}
		],
		"name": "getTokenStatus",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price1",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price2",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price3",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "price4",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xd7033eC4d1Eea9d7830F10B612790d3a57E0166b";

let contract;

class Mint2 extends Component {

	state = {
		_galleryData: [],
		_galleryData1: [],
		_galleryData2: [],
		_galleryData3: [],
		_galleryData4: [],
		_galleryShow: [],
		_galleryShow1: [],
		_galleryShow2: [],
		_galleryShow3: [],
		_galleryShow4: [],
		_loginWindow: 0,
		_imgURL: '',
		_attr: '',
		_edition: '',
		_ID: '',
		_images: 0,
		_images1: 0,
		_images2: 0,
		_images3: 0,
		_images4: 0,

		walletAddress: "",
		totalSupply: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		nftMintingAmount: '1',
		totalValue: "8000000000000000",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false
	}

	async comingFromHomePage() {

		const isMobile = window.innerWidth < 768;
		console.log("mobileComponent : " + isMobile);

		if (isMobile) {

			window.open("https://metamask.app.link/dapp/adhdbrosnfts3.pages.dev/gallery");

			//this.mm();

		}
	}

	async componentDidMount() {

		if (typeof window.ethereum == 'undefined') {
			this.comingFromHomePage();
		}


		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contract = new web3.eth.Contract(ABI, address);
					console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 80001;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],*/

													chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
												},
											],
										});
									}
								}
							}


							try {

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.paused().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 1000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 1000) {

										onlyLeft = 1000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;



										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 80001;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],*/

													chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
												},
											],
										});
									}
								}
							}


							try {

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.paused().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 1000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;



									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 1000) {

										onlyLeft = 1000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;



										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}

						//})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

			/*	const isMobile = window.innerWidth < 768;
				console.log("mobileComponent : " + isMobile);
	
				if(isMobile){
					this.mm();
				}	
			*/
		}

		let galleryShow3 = [];

		for (let i = 851; i <= 875; i++) {
			galleryShow3[i] = i;
		}

		this.setState({ _galleryShow3: galleryShow3 });
		this.setState({ _images3: 1 });
	}


	async pages2(pageNo) {
		let galleryShow2 = [];

		console.log("pageNo : " + pageNo);
		let o = ((pageNo - 1) * 25) + 601;

		for (let i = o; i < o + 25; i++) {
			galleryShow2[i - 601] = i;
		}

		this.setState({ _galleryShow2: galleryShow2 });
		this.setState({ _images2: 1 });

	}

	async goToOpensea(id) {
		var picID = id - 1;

		window.open("https://opensea.io/assets/ethereum/0x8626c56c9225d7b19f624dd20aa6a745279ab842/" + picID);


	}


	galleryOpen = async event => {
		event.preventDefault();

		let galleryShow = [];

		for (let i = 1; i <= 10; i++) {

			galleryShow[i - 1] = i;
		}
		this.setState({ _galleryShow: galleryShow });
		this.setState({ _images: 1 });

	}

	async onSubmit2(tokenID) {

		try {

			console.log("tokenID :" + tokenID);
			let getTokenStatus = await contract.methods.getTokenStatus(tokenID).call();
			console.log("getTokenStatus :" + getTokenStatus);
			if (getTokenStatus == true) {

				console.log("Go to Rariable");
				let openURL = "https://testnets.opensea.io/assets/mumbai/0xd7033ec4d1eea9d7830f10b612790d3a57e0166b/" + tokenID;
				window.open(openURL);
			} else {
				let owner = await contract.methods.owner().call();

				if (account != owner) {


					try {

						console.log("minAmount:" + mintAmount);
						console.log("valueOfNFTs:" + valueOfNFTs);

						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(tokenID).send({ gasLimit: '385000', from: account, value: '50000000000000000' });
						this.setState({ statusLoading: false, success: true });
						await new Promise(resolve => setTimeout(resolve, 5000));
						window.location.reload(true);

					} catch (err) {
						this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
						console.log(err);


					}
				} else {

					try {


						console.log("minAmount:" + mintAmount);
						console.log("valueOfNFTs:" + valueOfNFTs);

						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(tokenID).send({ gasLimit: '385000', from: account, value: this.state.totalValue * 0 });
						this.setState({ statusLoading: false, success: true });
						await new Promise(resolve => setTimeout(resolve, 5000));
						window.location.reload(true);

					} catch (err) {
						this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
						console.log(err);


					}






				}
			}
		} catch (err) {

			console.log(err);

		}
	}

	tier1 = async event => {
		event.preventDefault();

		let galleryShow1 = [];

		for (let i = 1; i <= 250; i++) {

			galleryShow1[1] = i;
		}
		this.setState({ _galleryShow1: galleryShow1 });
		this.setState({ _images1: 1 });

	}

	tier2 = async event => {
		event.preventDefault();

		let galleryShow2 = [];

		for (let i = 250; i < 500; i++) {

			galleryShow2[1] = i;
		}
		this.setState({ _galleryShow2: galleryShow2 });
		this.setState({ _images2: 1 });

	}

	tier3 = async event => {
		event.preventDefault();

		let galleryShow3 = [];

		for (let i = 501; i <= 750; i++) {

			galleryShow3[i - 1] = i;
		}
		this.setState({ _galleryShow3: galleryShow3 });
		this.setState({ _images3: 1 });

	}

	tier4 = async event => {
		event.preventDefault();

		let galleryShow4 = [];

		for (let i = 751; i <= 1000; i++) {

			galleryShow4[i - 1] = i;
		}
		this.setState({ _galleryShow4: galleryShow4 });
		this.setState({ _images4: 1 });

	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ _images: 0 });
		this.setState({ _images1: 0 });
		this.setState({ _images2: 0 });
		this.setState({ _images3: 0 });
		this.setState({ _images4: 0 });

	}

	mm = async event => {


		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				const contract = new web3mm.eth.Contract(ABI, address);


				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				try {
					localStorage.setItem('isWalletConnected', "true");
				} catch (ex) {
					console.log(ex)
				}

				const chainId = 80001;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
									

										chainName: 'Mumbai Testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
										rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
									},
								],
							});
						}
					}
				}


				try {

					try {
						localStorage.setItem('isWalletConnected', "true");
					} catch (ex) {
						console.log(ex)
					}

					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					publicSale = await contract.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					public_sale_cost = await contract.methods.publicSaleCost().call();
					this.setState({ _public_sale_cost: public_sale_cost });
					console.log("public_sale_cost :" + public_sale_cost);
					this.setState({ _cost: public_sale_cost });
					this.setState({ _mintStatus: false });

					MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
					this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
					console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

					public_mint_status = await contract.methods.paused().call();
					this.setState({ _public_mint_status: public_mint_status });
					console.log("public_mint_status :" + public_mint_status);

					owner = await contract.methods.owner().call();
					console.log("Owner" + owner);
					//...........................................................................//
					if (owner == account) {
						console.log("owner : " + owner)
						onlyLeft = 1000 - totalSupplyNFT;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}

						valueOfNFTs = mintAmount * 0;



						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					} else {
						mintAmount = 1;

						if (totalSupplyNFT == 1000) {

							onlyLeft = 1000 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							valueOfNFTs = mintAmount * this.state._cost;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						}
					}


				} catch (err) {
					console.log("err: " + err);

				}

			} catch (error) {
				console.error(error);
				// Handle errors or user rejections
			}
		} else {
			window.open("https://metamask.app.link/dapp/adhdbrosnfts3.pages.dev/Silver");
		}
		//}
		
	};


	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			/*coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}*/
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 80001;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
										},
									],
								});
							}
						}
					}


					try {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.paused().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//...........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 1000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 1000) {

								onlyLeft = 1000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///.................................///



						//...........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}

				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 80001;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
										},
									],
								});
							}
						}
					}


					try {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.paused().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//...........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 1000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 1000) {

								onlyLeft = 1000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///.................................///



						//...........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	render() {
		return (

			<div>

				<div class="page-loader">
					<div class="parent-container">
						<div class="loader"></div>
						<div class="loadTxt">LOADING</div>
					</div>
				</div>

				<div class="galleryMainGold">

					<div class="page-loader">
						<div class="parent-container">
							<div class="loader"></div>
							<div class="loadTxt">LOADING</div>
						</div>
					</div>
					
					<Element name="up">
					<div class="headers">

						<div class="headers2">
							<div><div class="introMint" onClick={() => window.location.href = '/'}>HOME</div></div>

							<div class="tierMain">
									<div class="tier" onClick={() => window.location.href = '/Silver'}>SILVER</div>
									<div class="tierOn" onClick={() => window.location.href = '/Gold'}>GOLD</div>
									<div class="tier" onClick={() => window.location.href = '/Platinum'}>PLATINUM</div>
								</div>

							<div class="right">							


								<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >WALLET <br /> CONNECT</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>

								<div class="connect2Mob">
									{this.state.walletAddress === '' ?
										(<div>
											<button onClick={this.walletConnect} class="wallet2">
											WALLET <br /> CONNECT
											</button>
										</div>) : (<div>
											<button onClick={this.walletDisconnect} class="wallet2">
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}
											</button>
										</div>)}
								</div>

							</div>

						</div>

					</div>
					</Element>

					<div class="galleryPicsMain">
						<div class="galleryPics">

							{this.state._galleryShow2.map((gallery) => {
								let imageLink = "https://adhdbros-gold.pages.dev/gold/" + gallery + ".png";
								console.log("imageLink :" + imageLink);
								return (
									<div class="imgName">
										<div class="hover01 column">
											<div>
												<figure><img src={imageLink} /></figure>
											</div>
										</div>
										<div class="imgNameDiv">#{gallery}</div>
										{this.state.walletAddress === '' ?

											(<button class="mintBtnDis">BUY</button>) :
											(<button class="mintBtn" onClick={() => this.onSubmit2(gallery)}>BUY</button>)}
									</div>
								);
							})}
						</div>
					</div>

					<img className='cool' src={cool} />
					<Link to="up" id="sticky-button" spy={true} smooth={true} duration={550}><div className='dynamiteDiv4'><img class="stickyBtn2" src={up} /></div></Link>

					<div class="numbers">
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(1)}>1</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(2)}>2</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(3)}>3</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(4)}>4</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(5)}>5</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(6)}>6</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(7)}>7</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(8)}>8</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(9)}>9</button></Link>
					<Link activeClass="" id="fontSize" to="up" spy={true} smooth={true} duration={550}><button class="numbers" onClick={() => this.pages2(10)}>10</button></Link>
					</div>

				</div>

				<div>

					{this.state.statusError ?
						(<div class="formMain2L">
							<form onClick={this.closeWindow2}>
								<button class="closeNote">✖</button>
							</form>
							<div class="formMain-2L">

								<div>
									<div class="errorMessage">
										<div >Sorry, something went wrong please try again later</div>
									</div>
								</div>
							</div>

						</div>) : null}

					{this.state.statusLoading ?
						(<div class="formMain2L">
							<div class="formMain-2L">

								<div class="loadingContainer">
									<div class="parent-container">
										<div class="loader2"></div>
									</div>
								</div>

							</div>

						</div>) : null}

					{this.state.success ?
						(<div class="formMain2L">
							<div class="formMain-2L">

								<div>
									<img class="check" src={check} />

									<div class="success-message">
										<h2>Transaction Successful!</h2>
									</div>
								</div>
							</div>

						</div>) : null}

				</div>

			</div>
		)
	}
}

export default Mint2;

